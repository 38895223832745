.home-page {
    .navbar.navbar-transparent {
        background: #FFFFFF!important;
    }
    .navbar-transparent .navbar-nav .nav-item .nav-link:not(.btn), [class*="bg"] .navbar-nav .nav-item .nav-link:not(.btn) {
        color: rgba(0, 0, 21, 0.9)!important;
    }
    .navbar.navbar-transparent .navbar-nav .nav-item .nav-link {
        color: rgba(0, 0, 21, 0.9)!important;
    }
    .navbar .navbar-nav .nav-item .nav-link {
        color: rgba(0, 0, 21, 0.9)!important;
        font-size: 14px;
    }
}
.success-massage {
    h2 {
        color: green;
    }
    h4 {
        padding-bottom: 30px;
    }
}
.container-fluid {
    padding-right: unset!important;
    padding-left: unset!important;
}
.c-main {
    padding-top: unset!important;
}
.money-range {
    padding-top: 30px;
    padding-bottom: 15px;
    span span {
        font-size: 15px;
        bottom: 40%;
    }
    .input-range__slider {
        height: 1.5rem;
        width: 1.5rem;
        margin-top: -0.9rem;
    }
    .input-range__track {
        height: 0.5rem;
    }
    .input-range__label--min {
        bottom: -1.8rem;
        position: absolute;
        @media only screen and (min-width: 320px) {
            left: 20px;
        }
    }
    .input-range__label--max {
        bottom: -1.8rem;
        position: absolute;
        @media only screen and (min-width: 320px) {
            right: 20px;
        }
    }
}
.diversity {
    margin-top: 100px;
}
.usable {
    margin-top: 100px;
}
.free {
    margin-top: 100px;
}
.privacy {
    margin: 100px 0px;
}
.page-header .filter::after {
    background-color: rgba(0, 0, 0, 0.3);
}
.text-danger.close {
    float: right;
    padding: 10px 15px;
    font-size: 20px;
}
.text-danger.close:hover {
    background-color: #6bd098;
}
.h2-modal-header {
    padding: 30px 0px;
}
.home-page {
    .privacy__item {
        background: #fffbf8;
        box-shadow: 0 0 60px rgba(255,137,51,.1);
        border-radius: 56px;
        padding: 40px 30px 0;
        width: 240px;
        .text {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #758191;
            width: 192px;
            margin-top: 20px;
        }
    }
    .privacy__right {
        flex: 4;
        display: flex;
    }
    .privacy img {
        vertical-align: middle;
        border-style: none;
    }
    .privacy__left {
        flex: 2;
        padding: 0 20px 0 0;
    }
    .privacy__main {
        display: flex;
        i {
            font-size: 50px;
            color: orange;
        }
    }
    .privacy__content {
        background: rgba(136, 204, 86, 0.4) no-repeat 50%;
        background-size: contain;
        height: 572px;
        border-radius: 80px;
    }
    .free__item {
        padding: 10px;
        flex-grow: 1;
        max-width: 19%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .icon {
            height: 112px;
            width: 112px;
            box-sizing: border-box;
            border-radius: 50%;
            background: rgba(136, 204, 86, 0.4);
            z-index: 2;
        }
        .icon .position {
            font-family: Starz\ 2016;
            font-style: normal;
            font-weight: 400;
            font-size: 56px;
            line-height: 72px;
            text-align: center;
            color: #b5bcc6;
        }
        .text {
            margin-top: 50px;
            z-index: 2;
            color: #758191;
        }
        .border-hover {
            position: absolute;
            width: 100%;
            height: 120%;
            z-index: 1;
            margin-top: -30px;
            border: 1px solid #f4f4f4;
            background: #fff;
            border-radius: 40px;
            box-sizing: border-box;
        }
    }
    .justify-content-center {
        justify-content: center!important;
    }
    .free__list-items {
        display: flex;
        flex-wrap: wrap;
        margin-top: 50px;
        cursor: pointer;
    }
    .usable__list-items {
        display: flex;
        flex-wrap: wrap;
        margin-top: 50px;
        cursor: pointer;
    }
    .usable__item {
        padding: 10px;
        flex-grow: 1;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .icon {
            height: 150px;
            width: 112px;
            box-sizing: border-box;
            border-radius: 40px;
            background: rgba(136, 204, 86, 0.4);
            padding-top: 30px;
            z-index: 2;
        }
        .text {
            margin-top: 50px;
            z-index: 2;
            color: #758191;
        }
        .border-hover {
            position: absolute;
            width: 100%;
            height: 120%;
            z-index: 1;
            margin-top: -30px;
        }
    }
    .landing-title {
        font-style: normal;
        font-weight: 700;
        line-height: 51px;
        color: #424b59;
        text-transform: uppercase;
    }
    .container {
        max-width: 1250px;
        padding: 0;
    }
    .diversity__main {
        display: flex;
    }
    .diversity__right {
        flex: 2;
        img {
            width: 500px;
        }
    }
    .diversity__left {
        flex: 4;
        padding: 0 20px 0 0;
    }
    .fadeInDown {
        -webkit-animation-name: fadeInDown;
        animation-name: fadeInDown;
    }
    .animated {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .align-items-center {
        align-items: center!important;
    }
    .d-flex {
        display: flex!important;
    }
    .diversity__item {
        margin-top: 40px;
        img {
            vertical-align: middle;
            border-style: none;
        }
        .text {
            padding-left: 30px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #758191;
            width: 410px;
        }
        i {
            font-size: 50px;
            color: orange;
        }
    }
    .container {
        margin-right: auto;
        margin-left: auto;
        padding-right: 15px;
        padding-left: 15px;
        width: 100%;
    }
    @media only screen and (min-width: 260px) {
        .diversity__main {
            flex-direction: column;
        }
        .diversity__right img {
            width: 200px;
        }
        .free__item {
            max-width: 100%;
            margin: 50px 30px 0;
        }
        .privacy__main {
            flex-direction: column;
        }
        .privacy__left {
            text-align: center;
        }
        .privacy__right {
            flex-direction: column;
            align-items: center;
        }
        .privacy__left img {
            width: 200px;
        }
    }
    @media only screen and (min-width: 320px) {
        .diversity__main {
            flex-direction: column;
        }
        .diversity__right img {
            width: 300px;
        }
        .landing-title {
            font-size: 25px;
            text-align: center;
        }
        .diversity__right {
            text-align: center;
        }
        .usable__content {
            margin: 0 30px;
        }
        .landing-title {
            font-size: 25px;
            text-align: center;
        }
        .usable__list-items {
            flex-direction: column;
        }
        .usable__item {
            max-width: 100%;
            margin: 30px 0;
        }
        .free__list-items {
            flex-direction: column;
        }
        .free__item {
            max-width: 100%;
            margin: 50px 30px 0;
        }
        .privacy__content {
            margin: 0 30px;
            padding: 0;
            background: none;
        }
        .privacy__main {
            flex-direction: column;
        }
        .privacy__left {
            text-align: center;
            img {
                width: 300px;
            }
        }
        .privacy__right {
            flex-direction: column;
            align-items: center;
        }
        .privacy__item {
            margin: 0;
            :first-child {
                margin-top: 0;
            }
            :nth-child(2) {
                margin: 60px 0 0;
            }
        }
    }
    @media (min-width: 576px) {
        .container {
            max-width: 540px;
        }
    }
    @media (min-width: 768px) {
        .container {
            max-width: 720px;
        }
        .landing-title {
            text-align: left!important;
        }
        .usable__content {
            margin: 0 30px;
        }
        .landing-title {
            text-align: left!important;
        }
        .usable__list-items {
            flex-direction: row!important;
        }
        .usable__item {
            max-width: 20%!important;
            margin: 0!important;
        }
    }
    @media (min-width: 992px) {
        .container {
            max-width: 960px;
        }
        .diversity__main {
            flex-direction: row!important;
        }
    }
    @media only screen and (min-width: 992px) {
        .diversity__content {
            border-radius: 80px!important;
            background: rgba(136, 204, 86, 0.4) no-repeat 50%!important;
            height: 572px!important;
            padding: 100px!important;
        }
    }
    @media only screen and (min-width: 1200px) {
        .diversity__right img {
            width: 400px!important;
        }
        .privacy__left img {
            width: 400px!important;
        }
        .diversity__content {
            margin: 0 80px!important;
        }
        .container {
            max-width: 1140px;
        }
        .free__list-items {
            flex-direction: row!important;
        }
        .privacy__content {
            margin: 0 80px!important;
            padding: 100px!important;
            background: rgba(136, 204, 86, 0.4) no-repeat 50%!important;
        }
        .privacy__main {
            flex-direction: row!important;
        }
        .privacy__left {
            text-align: left;
        }
        .privacy__right {
            flex-direction: row!important;
        }
        .privacy__item:first-child {
            margin-top: 40px!important;
        }
        .privacy__item {
            margin: 0 0 0 40px!important;
            padding-bottom: 50px;
        }
        .privacy__item:nth-child(2) {
            margin: -10px 0 40px 40px!important;
        }
    }
    @media only screen and (min-width: 1220px) {
        .free__item {
            max-width: 18%!important;
            margin: 0 20px 0 0!important;
        }
    }
    @media only screen and (min-width: 1260px) {
        .privacy__left img {
            width: 400px!important;
        }
        .diversity__right img {
            width: 400px!important;
        }
    }
    @media only screen and (min-width: 1300px) {
        .landing-title {
            font-size: 40px!important;
        }
    }
    @media only screen and (min-width: 1370px) {
        .privacy__left img {
            width: 450px!important;
        }
        .diversity__right img {
            width: 450px!important;
        }
    }
}
@media only screen and (min-width: 280px) {
    .home-page .page-header {
        background-position: unset;
        min-height: 70vh;
    }
}
@media only screen and (min-width: 320px) {
    .home-page .page-header {
        background-position: unset;
        min-height: 70vh;
    }
}
@media only screen and (min-width: 320px) {
    .home-page .page-header {
        background-position: unset;
        min-height: 90vh;
    }
}
@media (min-width: 768px) {
    .home-page .page-header {
        background-position: center center;
        min-height: 80vh;
    }
}
@media only screen and (min-width: 1280px) {
    .home-page .page-header {
        min-height: 100vh;
    }
}
@media only screen and (min-width: 1600px) {
    .home-page .page-header {
        background-position: unset;
        min-height: 100vh;
    }
}
@media screen and (min-width: 768px) {
    .list-staff-home {
        padding-left: 80px;
        padding-right: 80px;
    }
    .list-content-home {
        padding-left: 80px;
        padding-right: 80px;
    }
}
.list-staff-home {
    .card {
        background-color: #fff;
        border: 1px solid #ccc;
        padding: 2%;
        border-radius: .25rem!important;
        margin-bottom: unset;
    }
    .card-title {
        color: #e55353;
        padding: 0px 10px 0 10px;
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
    }
    .card-body {
        span {
            font-weight: 600;
            font-size: 14px;
        }
        padding: 0 10px 0 10px;
    }
    .card-footer {
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: center;
        padding: unset;
    }
    a {
        padding: .375rem .75rem;
    }
    .box-img-helpmeet-detail {
        background: #6bd098;
        min-width: 52px;
        padding: 15px;
    }
    .seprator-gr {
        height: 2px;
        width: 56px;
        background-color: #6bd098;
        margin: 7px 0 10px 0;
    }

    .rounded-circle {
        border-radius: 50%!important;
    }
    .box-helpmeet-des {
        .col-md-10 {
            padding-left: 30px;
        }
    }
}
.list-content-home {
    img {
        max-width: 100%;
        display: block;
        margin: auto;
    }
    .card {
        background-color: #fff;
        border: 1px solid #ccc;
        padding: 2%;
        border-radius: .25rem!important;
        margin-bottom: unset;
    }
    .card-title {
        color: #e55353;
    }
    .card-body {
        span {
            font-weight: 600;
            font-size: 14px;
        }
        padding: unset;
    }
    .card-footer {
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        padding: unset;
    }
    a {
        padding: .375rem .75rem;
    }
}
.header-home {
    text-align: center;
    margin-bottom: 20px;
}
.jumbotron {
    a {
        color: #321fdb;
    }
}
@media (max-width: 576px) {
    .box-img-helpmeet-detail {
        display: none;
    }
}
@media (max-width: 907px) {
    .box-img-helpmeet-detail {
        min-width: 30px;
        padding: 4px;
    }
}
@media (max-width: 1251px) {
    .box-img-helpmeet-detail {
        min-width: 40px;
        padding: 10px;
    }
}
strong {
    font-weight: bold;
}
.service {
    background: #f5f5f5;
    .service-title {
        padding-top: 35px;
        padding-bottom: 26px;
        text-align: center;
        h3 {
            margin-bottom: .5rem;
            font-family: inherit;
            font-weight: 500;
            line-height: 1.1;
            color: inherit;
            a {
                color: #444;
                font-size: 30px;
                font-weight: bold;
                color: inherit;
                text-decoration: none;
            }
        }
        .service-list .col-md-4 {
            display: inline-flex;
            position: relative;
        }
    }
    .card-block.service-list-item-info h3 {
        font-size: 20px;
        padding: 0 25px 5px;
        line-height: 30px;
        text-align: center;
    }
    .card-block.service-list-item-info {
        text-align: center;
        padding-bottom: 30px;
    }
    .card-block {
        padding: 1.25rem;
    }
    .backradi {
        position: absolute;
        top: 0;
        width: 100%;
        height: 247px;
        background: linear-gradient(#fff0,#fff0,#1d62ff9c);
    }
    .service-list-item .card {
        position: relative;
    }
    .service-list-item .card {
        border: 0;
    }
    .card {
        position: relative;
        display: block;
        margin-bottom: .75rem;
        background-color: #fff;
        border-radius: .25rem;
        border: 1px solid rgba(0,0,0,.125);
    }
    a {
        background-color: transparent;
        -webkit-text-decoration-skip: objects;
        color: #0275d8;
        text-decoration: none;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
    }
    .service-list .col-md-4 {
        display: inline-flex;
        position: relative;
    }
    .service-list-item .card img {
        width: 100%;
        height: 247px;
    }
    .icon-service {
        width: 100%;
        position: absolute;
        top: 190px;
        left: 0;
        border-radius: 50%;
        text-align: center;
        line-height: 72px;
        z-index: 11;
    }
    .ava-icon-service {
        width: 73px;
        height: 73px;
        margin: 0 auto;
        background: #8fc541;
        cursor: pointer;
        border-radius: 50%;
    }
    .icon-service img {
        width: 30px!important;
        height: 30px!important;
    }
}
.tit-jvwork h3 {
    text-align: center;
    padding-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
    color: inherit;
    text-decoration: none;
    margin-top: unset;
}
.numbers-title h3 {
    margin-top: 50px;
    text-align: center;
    padding-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
    color: inherit;
    text-decoration: none;
}
.nv-item1 .card img {
    padding: 56px 43px;
    border-radius: 50%;
    border: 1px solid #ebebeb;
    object-fit: contain;
    width: 160px;
    height: 160px;
}
.nv-item1 .card {
    border: 0;
    text-align: center;
}
.card-block-item1 h3 {
    font-size: 18px;
    color: #333;
    padding-bottom: 5px;
    font-weight: bold;
}
.nv-content1-item2 {
    margin-top: 50px;
    padding: 50px;
    background: white;
}
span.count, span.number-noli {
    font-size: 36px;
}
span.count, span.count1, span.number-noli {
    color: #8fc541;
    font-weight: bold;
}
.numbers-desc, .info-number {
    text-align: center;
    .col-md-4 {
        padding-bottom: 40px;
    }
}
.advance {
    h3 {
        text-align: center;
        color: #444;
        font-size: 30px;
        font-weight: bold;
        color: inherit;
        text-decoration: none;
        margin-top: 50px;
    }
}
.benefit {
    margin: 0;
    grid-gap: 30px;
    display: grid;
    overflow: hidden;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: ". .";
    .col-banner-left {
        text-align: right;
        justify-content: flex-start;
        .list-advance {
            margin-top: 30px;
            .box-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                margin-bottom: 20px;
                .content {
                    text-align: left;
                    display: block;
                    padding-left: 20px;
                    order: 1;
                    .detail {
                        font-size: 16px;
                        line-height: 22px;
                        letter-spacing: .03em;
                        color: #8D8D8D;
                        font-weight: 300;
                        padding-top: 10px;
                    }
                    .title {
                        font-weight: 500;
                        font-size: 22px;
                        line-height: 28px;
                        color: #505050;
                        position: relative;
                        display: inline-block;
                        transition: all 1s;
                    }
                }
                .icon img {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }
    .col-banner-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .image-banner img {
            height: auto;
        }
    }
    .title {
        margin-top: unset;
    }
}
.contact {
    background: white;
    .service-title {
        padding-top: 30px;
    }
    h3 {
        text-align: center;
        padding-bottom: 30px;
        font-size: 30px;
        font-weight: bold;
        color: inherit;
        text-decoration: none;
    }
    .btn-success {
        background-color: #00aff2;
        border-color: #00aff2;
    }
}
#root .home-page .footer {
    background-color: #ebedef;
}
.navbar-toggler {
  padding: .75rem 0.75rem;
}
.service .service-list .col-md-4 {
  margin-bottom: 15px;
}
.service .card-block.service-list-item-info h3 a {
  font-weight: bold;
}

@media only screen and (max-width: 991px) {
    .service .service-list-item .card img {
      width: 100%;
      height: auto;
      margin-top: -15px;
    }
    .service .ava-icon-service {
      width: 60px;
      height: 60px;
      margin: -105px auto;
      background: #8fc541;
      cursor: pointer;
      border-radius: 50%;
    }
    .service .card-block.service-list-item-info h3 {
      font-size: 17px;
      padding: 0 25px 5px;
      line-height: 20px;
      text-align: center;
    }
    .benefit {
      display: block;
    }
    .benefit .col-banner-right .image-banner{
      text-align: center;
    }

    .justify-content-center .col-md-6 {
        max-width: 100%;
        flex: 0 0 100%;
    }
    .col-md-6 .text-light,
    .col-md-6 .text-light .align-items-center .mb-0 {
      text-align: center;
    }
    .home-page .d-flex {
      display: block !important;
    }
    .form-group .text-label-contact {
      text-align: center;
    }
    .numbers-desc.wow.fadeInDown.wHighlight {
      padding: 0px 20px 0px 20px;
    }
}
@media only screen and (max-width: 768px) {
  .benefit {
    display: block;
    grid-gap: 30px;
    margin: 0;
  }
  .service .service-list-item .card img {
    width: 100%;
    height: auto;
    margin-top: -25px;
  }
  .service .ava-icon-service {
    width: 50px;
    height: 50px;
    margin: -150px auto;
    background: #8fc541;
    cursor: pointer;
    border-radius: 50%;
  }
  .service .icon-service img {
    width: 25px !important;
    height: 25px !important;
  }
  .service .card-block.service-list-item-info h3 {
    font-size: 15px;
    padding: 0 5px 5px;
    line-height: 20px;
    text-align: center;
  }
  .service .card-block {
    padding: 1rem;
  }
}
@media only screen and (max-width: 576px) {
    .advance .banner {
        margin: 0;
    }
    .service .service-list-item .card img {
      width: 100%;
      height: auto;
    }
  .advance h3,
  .benefit .col-banner-left .list-advance .box-item .content .title{
    font-size: 20px;
  }
  .nv-item1 .card img {
    width: 150px;
    height: 150px;
  }
  .navbar-toggler {
    padding: .75rem 0.75rem;
  }
  .card-block-item1 {
    font-size: 14px;
  }
  .col-md-12 .font-weight-bold.h5 {
    text-align: center;
  }
  footer.footer.footer-black.footer-white {
    padding-left: 30px;
  }
  .contact .col-sm-7 {
    text-align: center;
  }
}
